<template>
    <div class="client-manager" :key="generatedKey" v-if="this.$store.state.clientOverview.loaded">
        <div class="member-profile-sidebar">
            <div class="user-profile-header member-profile-header">
                <!-- <UploadOverlay/> -->
                <div class="member-profile-photo">
                    <UploadOverlay
                        :storeGetterKey="'clientOverview/getClientProfilePhoto'"
                        :storeUpdateFileKey="'clientOverview/updateField'"
                        :storeUpdateFileField="'clientProfilePhoto'"
                        :storeUpdateFileIDKey="'clientOverview/updateClientProfileId'"
                        :storeUpdateFileIDField="'client_profile_photo_file_id'"
                        :storeFileID="$store.state.clientOverview.clientData.client_profile_photo_file_id"
                        kind="client_profile_photo"
                        :client="$store.getters['clientOverview/client']"
                        :urlToUpload="`/clients/client-profile-photo?fileId=${$store.state.clientOverview.clientData.client_profile_photo_file_id}`"
                        v-if="
                            $store.state.clientOverview && clientId !== 'new'
                                ? $store.state.clientOverview.loaded
                                : false
                        "
                        :alt="''"
                    />
                    <!-- <UploadOverlay
                        :kind="'client_license'"
                        :urlToUpload="`/clients/client-license?fileId=`"
                        :alt="''"
                        v-if="clientId == 'new'"
                        :notApiPersistent="true"
                        @returnLocalData="giveDataToGeneral"
                    /> -->
                    <!-- <img src="assets/px/client-profile-placeholder.png" alt=""/> -->
                </div>
                <!-- <div class="client-info" v-if="clientId !== 'new'"> -->
                <div class="client-info">
                    <div class="client-full-name">
                        <b>{{ $store.getters['clientOverview/clientFullName'] }}</b>
                    </div>
                    <div class="internal-id">
                        Internal ID# {{ $store.getters['clientOverview/client'].id }} -
                        {{ $store.getters['clientOverview/clientStatus'] }}
                    </div>

                    <div class="client-diseases">
                        <!-- <h3>Diseases: Diabetis</h3> -->
                    </div>
                    <div class="member-profile-sidebar-buttons flex">
                        <button
                            class="secondary top-10"
                            type="button"
                            @click="
                                () => {
                                    goToPage(sideBarTabpages[0]);
                                }
                            "
                        >
                            Medications
                        </button>
                        <button
                            class="primary top-10"
                            type="button"
                            @click="
                                () => {
                                    goToPage(sideBarTabpages[1]);
                                }
                            "
                        >
                            Message
                        </button>
                    </div>
                </div>
                <div class="member-profile-sidebar-section2">
                    <!-- live vitals will go here -->
                </div>
                <!-- <div class="client-activity">
                    <div>
                        <b>{{ $store.getters['clientOverview/clientStatus'] }}</b>
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits attended:
                        {{ this.$store.state.clientOverview.attendance.attended }}
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits missed:
                        {{ this.$store.state.clientOverview.attendance.missed }}
                    </div>
                </div> -->
                <div class="member-profile-sidebar-section2">
                    <!-- live vitals will go here -->
                </div>
            </div>
            <!-- <ClientManagerTabs :pages="tabPages" @action="goToPage" v-if="clientId !== 'new'" /> -->
        </div>
        <div class="client-manager-tabs">
            <ClientManagerTabs :pages="tabPages" @action="goToPage" />
        </div>
        <!-- <div class="client-demographics-card flex">
            <div class="flex-6 left-col margin three-col-fields">
                <ClientDemographicsGeneral :client-id="clientId" @assignId="assignId" :newLicense="newLicense" />
                <ClientDemographicsSocial :client-id="clientId" @assignId="assignId" :newLicense="newLicense" />
                <ClientDemographicsWork :client-id="clientId" @assignId="assignId" :newLicense="newLicense" /> -->

        <!--                <ClientReferralInfo :client-id="clientId" v-if="clientId !== 'new'" />-->

        <!-- <ClientMedicalInfo :client-id="clientId" v-if="clientId !== 'new'" />
            </div>
            <div class="flex-4">
                <ClientContactInfo
                    :client-id="useClientId()"
                    v-if="clientId !== 'new' && $store.getters['clientOverview/client']"
                />
                <ClientContactPermissions :client-id="clientId" @assignId="assignId" :newLicense="newLicense" />
                <ClientMandateInfo :client-id="clientId" v-if="clientId !== 'new'" />
            </div>
        </div> -->
        <!-- New Demographics design start -->

        <div class="client-demographics-card">
            <ClientDemographicsGeneral
                :client-id="clientId"
                @assignId="assignId"
                :newLicense="newLicense"
                @generalInfoChange="onGeneralInfoChange"
            />
            <!-- <ClientDemographicsProfile :client-id="clientId"  @clientProfileInfoChange="onClientProfileInfoChange" /> -->
            <ClientDemographicsDashboardBuilder
                :clientId="clientId"
                :clientDashboardInfo="clientDashboardInfo"
                @handleDashboardInfo="handleDashboardInfo"
                @clientProfileInfoChange="onClientProfileInfoChange"
            />
            <ClientDemographicsLookupTable
                :clientId="clientId"
                :clientDashboardInfo="clientDashboardInfo"
                :lookupTableData="lookup_table_data"
                @handleLookupInfo="handleLookupInfo"
                @clientLookupInfoChange="onClientLookupInfoChange"
            />
            <ClientContactInfo
                :client-id="useClientId()"
                v-if="clientId !== 'new' && $store.getters['clientOverview/client']"
                @clientContactInfoChange="onContactInfoChange"
            />
            <ClientDemographicsSocial
                :client-id="clientId"
                @assignId="assignId"
                :newLicense="newLicense"
                @socialInfoChange="onSocialInfoChange"
            />
            <ClientContactPermissions
                :client-id="clientId"
                @assignId="assignId"
                :newLicense="newLicense"
                @contactPermissionsChange="onContactPermissionsChange"
            />
            <ClientDemographicsWork
                :client-id="clientId"
                @assignId="assignId"
                :newLicense="newLicense"
                @workInfoChange="onWorkInfoChange"
            />
            <ClientMandateInfo
                :client-id="clientId"
                v-if="clientId !== 'new'"
                @mandatedInfoChange="onMandatedInfoChange"
            />
            <ClientMedicalInfo
                :client-id="clientId"
                v-if="clientId !== 'new'"
                @medicalInfoChange="onMedicalInfoChange"
            />
            <div class="d-flex justify-content-end">
                <button type="button" class="primary btn-demographics-save px-5" @click="submitAllFormData">
                    Save
                </button>
            </div>
        </div>

        <!-- New Demographics design end -->
    </div>
    <Loading v-else-if="this.$store.state.clientOverview.loaded == false" />
</template>
<script>
    // import ClientReferralInfo from '@/components/client-manager/ClientReferralInfo';
    import ClientMandateInfo from '@/components/client-manager/ClientMandateInfo';
    import ClientMedicalInfo from '@/components/client-manager/ClientMedicalInfo';
    import ClientContactInfo from '@/components/client-manager/ClientContactInfo';
    import ClientContactPermissions from '@/components/client-manager/ClientContactPermissions';
    import ClientDemographicsGeneral from '@/components/client-manager/ClientDemographicsGeneral';
    import ClientDemographicsSocial from '@/components/client-manager/ClientDemographicsSocial';
    import ClientDemographicsWork from '@/components/client-manager/ClientDemographicsWork';
    import ClientManagerTabs from '@/components/client-manager/ClientManagerTabs.vue';
    import UploadOverlay from '@/components/general/Upload/UploadOverlay.vue';
    import { generateId } from '@/util/genericUtilityFunctions';
    import Loading from '@/components/general/loading/loading.vue';
    import OfficeDropdown from '@/components/general/validatedInputs/OfficeDropdown';
    import ClientDemographicsProfile from '@/components/client-manager/ClientDemographicsProfile';
    import ClientDemographicsDashboardBuilder from '@/components/client-manager/ClientDemographicsDashboardBuilder';
    import ClientDemographicsLookupTable from '@/components/client-manager/ClientDemographicsLookupTable';
    import { clients } from '@/util/apiRequests';

    export default {
        name: 'ClientDemographics',
        components: {
            ClientDemographicsGeneral,
            ClientDemographicsSocial,
            ClientDemographicsWork,
            ClientContactInfo,
            ClientContactPermissions,
            //ClientReferralInfo,
            ClientMandateInfo,
            ClientMedicalInfo,
            ClientManagerTabs,
            UploadOverlay,
            Loading,
            OfficeDropdown,
            ClientDemographicsProfile,
            ClientDemographicsDashboardBuilder,
            ClientDemographicsLookupTable,
        },
        provide() {
            return {
                storeFileId: this.$store.state?.clientOverview?.clientData?.license_id,
            };
        },
        data() {
            return {
                generatedKey: generateId(),
                newLicense: null,
                // clientId: this.$route.params.client_id,
                // tabPages: [
                //     { label: 'Overview', name: 'ClientOverview', params: { record_id: this.useClientId() } },
                //     {
                //         label: 'Demographics',
                //         name: 'ClientDemographics',
                //         params: { client_id: this.useClientId() }
                //     },
                //     {
                //         label: 'Insurance',
                //         name: 'ClientInsurance',
                //         params: { client_id: this.useClientId() }
                //     },
                //     { label: 'Billing', name: 'ClientBilling', params: { client_id: this.useClientId() } },
                //     { label: 'Clinical', name: 'ClientClinical', params: { client_id: this.useClientId() } },
                //     {
                //         label: 'Documents & Forms',
                //         name: 'ClientDocuments',
                //         params: { client_id: this.useClientId() }
                //     }
                // ]

                generalInfoContext: null,
                contactInfoContext: null,
                socialInfoContext: null,
                contactPermissionsContext: null,
                workInfoContext: null,
                mandatedInfoContext: null,
                medicalInfoContext: null,
                clientProfileInfoContext: null,
                clientLookupInfoContext: null,
                clientDashboardInfo: {
                    dashboardUsed: null,
                    kiosk_dashboard_used: null,
                    mhh_dashboard_used: null,
                    family_connect_dashboard_used: null,
                    star_kid_dashboard_used: null,
                    mobex_plus_dashboard_used: null,
                    mobex_health_home_dashboard_used: null,
                    lookup_table_data: null,
                },
                lookup_table_data: [],
            };
        },
        computed: {
            tabPages() {
                return [
                    { label: 'Overview', name: 'ClientOverview', params: { record_id: this.clientId } },
                    {
                        label: 'Demographics',
                        name: 'ClientDemographics',
                        params: { client_id: this.clientId },
                    },

                    // {
                    //     label: 'Insurance',
                    //     name: 'ClientInsurance',
                    //     params: { client_id: this.clientId }
                    // },

                    //{ label: 'Billing', name: 'ClientBilling', params: { client_id: this.clientId } },
                    { label: 'Clinical', name: 'ClientClinical', params: { client_id: this.clientId } },
                    {
                        label: 'Documents & Forms',
                        name: 'ClientDocuments',
                        params: { client_id: this.clientId },
                    },
                    {
                        label: 'Photos',
                        name: 'ClientPhotos',
                        params: { client_id: this.clientId },
                        // icon_label:"docs-and-forms"
                    },
                    {
                        label: 'Link sharing',
                        name: 'Link Sharing',
                        params: { client_id: this.clientId },
                        // icon_label:"docs-and-forms"
                    },
                    {
                        label: 'Vitals',
                        name: 'Vitals',
                        params: { client_id: this.clientId },
                        // icon_label:"docs-and-forms"
                    },
                ];
                //return
            },
            sideBarTabpages() {
                return [
                    {
                        label: 'Medications',
                        name: 'Medications',
                        params: { client_id: this.clientId },
                        // icon_label:"docs-and-forms"
                    },
                    {
                        label: 'Message',
                        name: 'Messaging',
                        params: { client_id: this.clientId },
                        // icon_label:"docs-and-forms"
                    },
                ];
            },
            clientId() {
                return this.$store.state.current_client_id;
            },
        },
        methods: {
            goToPage(page) {
                this.$router.push(page);
                this.$forceUpdate();
            },
            assignId(id) {
                this.$router.replace({ params: { client_id: id } });
            },
            giveDataToGeneral(data) {
                this.newLicense = data;
            },
            useClientId() {
                if (this.clientId) {
                    return this.clientId;
                } else {
                    return this.$route.params.client_id;
                }
            },
            onGeneralInfoChange({ context }) {
                this.generalInfoContext = context;
            },
            onClientProfileInfoChange({ context }) {
                this.clientProfileInfoContext = context;
            },
            onClientLookupInfoChange({ context }) {
                this.clientLookupInfoContext = context;
            },
            onContactInfoChange({ context }) {
                this.contactInfoContext = context;
            },
            onSocialInfoChange({ context }) {
                this.socialInfoContext = context;
            },
            onContactPermissionsChange({ context }) {
                this.contactPermissionsContext = context;
            },
            onWorkInfoChange({ context }) {
                this.workInfoContext = context;
            },
            onMandatedInfoChange({ context }) {
                this.mandatedInfoContext = context;
            },
            onMedicalInfoChange({ context }) {
                this.medicalInfoContext = context;
            },
            async submitAllFormData() {
                const isGeneralInfoFormValid = await this.generalInfoContext.$refs.form.validate();
                const isContactInfoFormValid = await this.contactInfoContext.$refs.form.validate();
                const isSocialInfoFormValid = await this.socialInfoContext.$refs.form.validate();
                const isContactPermissionsFormValid = await this.contactPermissionsContext.$refs.form.validate();
                const isWorkInfoFormValid = await this.workInfoContext.$refs.form.validate();
                const isMandatedInfoFormValid = await this.mandatedInfoContext.$refs.form.validate();
                const isMedicalInfoFormValid = await this.medicalInfoContext.$refs.form.validate();

                if (
                    !isGeneralInfoFormValid ||
                    !isContactInfoFormValid ||
                    !isSocialInfoFormValid ||
                    !isContactPermissionsFormValid ||
                    !isWorkInfoFormValid ||
                    !isMandatedInfoFormValid ||
                    !isMedicalInfoFormValid
                ) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }

                // if (!this.generalInfoContext.isGeneralInfoFormChanged && !this.contactInfoContext.isContactInfoFormChanged &&
                // !this.socialInfoContext.isSocialInfoFormChanged && !this.contactPermissionsContext.isContactPermissionsFormChanged &&
                // !this.workInfoContext.isWorkInfoFormChanged && !this.mandatedInfoContext.isMandateInfoFormChanged &&
                // !this.medicalInfoContext.isMedicalInfoFormChanged ) {

                //     return;

                // }

                if (this.generalInfoContext.isGeneralInfoFormChanged) {
                    await this.generalInfoContext.submit();
                }

                if (this.contactInfoContext.isContactInfoFormChanged) {
                    await this.contactInfoContext.submit();
                }

                if (this.socialInfoContext.isSocialInfoFormChanged) {
                    await this.socialInfoContext.submit();
                }

                if (this.contactPermissionsContext.isContactPermissionsFormChanged) {
                    await this.contactPermissionsContext.submit();
                }

                if (this.workInfoContext.isWorkInfoFormChanged) {
                    await this.workInfoContext.submit();
                }

                if (this.mandatedInfoContext.isMandateInfoFormChanged) {
                    await this.mandatedInfoContext.submit();
                }

                if (this.medicalInfoContext.isMedicalInfoFormChanged) {
                    await this.medicalInfoContext.submit();
                }

                if (
                    this.clientProfileInfoContext.isProfileInfoFormChanged ||
                    this.clientLookupInfoContext.isProfileInfoFormChanged
                ) {
                    await this.submit();
                }

                this.$toasted.success('Member Demographics Saved Successfully');
                this.resetAllFormFieldsState();
            },
            resetAllFormFieldsState() {
                this.generalInfoContext.isGeneralInfoFormChanged = false;
                this.contactInfoContext.isContactInfoFormChanged = false;
                this.socialInfoContext.isSocialInfoFormChanged = false;
                this.contactPermissionsContext.isContactPermissionsFormChanged = false;
                this.workInfoContext.isWorkInfoFormChanged = false;
                this.mandatedInfoContext.isMandateInfoFormChanged = false;
                this.medicalInfoContext.isMedicalInfoFormChanged = false;
                this.clientProfileInfoContext.isProfileInfoFormChanged = false;
                this.clientLookupInfoContext.isProfileInfoFormChanged = false;
            },
            async getAndSetClientProfileInfo() {
                try {
                    let res = await this.$api.get(clients.getClientProfileInfo(this.clientId));
                    if (res.data[0]) {
                        const {
                            dashboardUsed,
                            kiosk_dashboard_used,
                            mhh_dashboard_used,
                            family_connect_dashboard_used,
                            star_kid_dashboard_used,
                            mobex_plus_dashboard_used,
                            mobex_health_home_dashboard_used,
                            lookup_table_data,
                        } = res.data[0];

                        this.clientDashboardInfo = {
                            dashboardUsed,
                            kiosk_dashboard_used,
                            mhh_dashboard_used,
                            family_connect_dashboard_used,
                            star_kid_dashboard_used,
                            mobex_plus_dashboard_used,
                            mobex_health_home_dashboard_used,
                        };

                        this.lookup_table_data = JSON.parse(lookup_table_data);
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch member profile and theme info');
                }
            },
            handleDashboardInfo(val, field) {
                this.clientDashboardInfo[field] = val?.id ?? null;
                if (!val) {
                    return;
                }
                if (val.application === 'kiosk') {
                    this.clientDashboardInfo.kiosk_dashboard_used = val.id;
                } else if (val.application === 'mobex-health-hub') {
                    this.clientDashboardInfo.mhh_dashboard_used = val.id;
                } else if (val.application === 'family-connect') {
                    this.clientDashboardInfo.family_connect_dashboard_used = val.id;
                } else if (val.application === 'star-kid') {
                    this.clientDashboardInfo.star_kid_dashboard_used = val.id;
                } else if (val.application === 'mobex-health-home') {
                    this.clientDashboardInfo.mobex_health_home_dashboard_used = val.id;
                } else {
                    this.clientDashboardInfo.mobex_plus_dashboard_used = val.id;
                }
            },
            handleLookupInfo(val) {
                if (val) {
                    this.lookup_table_data = val;
                }
            },
            async submit() {
                try {
                    const endpoint = clients.putClientProfileInfo(this.clientId);
                    await this.$api.put(endpoint, {
                        ...this.clientDashboardInfo,
                        lookup_table_data: this.lookup_table_data,
                    });

                    if (this.clientProfileInfoContext?.isProfileInfoFormChanged) {
                        await this.clientProfileInfoContext?.sendReminderDashboardChangeNotification();
                        await this.getAndSetClientProfileInfo();
                    }
                } catch (err) {
                    this.$toasted.error('Could not save data.');
                }
            },
        },
        async created() {
            await this.getAndSetClientProfileInfo();
        },
        watch: {
            '$route.params.client_id': {
                handler(newVal, oldVal) {
                    this.$forceUpdate();
                    this.generatedKey = generateId();
                    //this.clientId = newVal;
                    // this.$forceUpdate();
                    // this.$store.state.clientOverview.loaded = false;
                    // this.$nextTick(() => {
                    //     this.$forceUpdate();
                    //     this.$store.state.clientOverview.loaded = true;
                    // }, 500);
                    // this.tabPages = this.tabPages.map((element) => {
                    //     let key = Object.keys(element.params).filter((thing) => thing.includes('id'))[0];
                    //     element.params[key] = newVal;
                    //     return element;
                    // });
                    // //This will allow the page to rerender despite
                    // //being the same route
                    // if (newVal == 'new' && oldVal !== null) {
                    //     this.$forceUpdate();
                    //     this.generatedKey = generateId();
                    // }
                },
            },
        },
    };
</script>
